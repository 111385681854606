import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";
import { connect } from "react-redux";
import Shelf from "../Shelf/shelf";
import Search from "../Search/search";
import Player from "../Player/player";
import styles from "./home.module.scss";
import { TouchableOpacity } from "react-native-web";
import SafeArea from "react-safe-area-component";
import ShelfPicker, {
  recentsShelfItem,
  staffPicksShelfItem
} from "../ShelfPicker/shelf-picker";
import {
  SEARCH_UI_PHASE_ARTIST_SELECTED,
  SEARCH_UI_PHASE_RECOMMENDED,
  SEARCH_UI_PHASE_RESULTS
} from "../../reducers/search-reducer";
import {
  PlaybackStates,
  stopPreviewingRecord
} from "../../actions/playback-actions";
import { jlog, isMobile, debounce, isMobileWeb } from "../../helpers";
import {
  isMe,
  setPickedItem,
  updateDocumentElement
} from "../../actions/ui-actions";
import _ from "lodash";
import { devMode, constant, Constants } from "../../helpers/flags";
import { ScrollView } from "react-native-web";
import SelectedRecord from "../SelectedRecord/selected-record";
import Flash from "../Flash/flash.js";
import { withCookies, Cookies } from "react-cookie";
import {
  CATALOG_ID_APPLE_MUSIC_NEW_RELEASES,
  CATALOG_ID_LABEL_NEW_RELEASES
} from "../../reducers/catalogs-reducer";
import {
  hardRefreshShelf,
  loadAppleMusicRecommendedAlbums,
  loadLabelRecords,
  loadSpinitronTop50,
  loadUserData
} from "../../actions/record-actions";
import {
  authenticate,
  unauthenticate
} from "../../actions/authentication-actions";
import { isContainedInIosNativeApp } from "../../helpers/native-ios-mk-implementation";
import DebugInfo from "../DebugInfo/debug-info";
import PickedItem from "../PickedItem/picked-item";
import {
  SCROLL_Y_TO,
  SET_PREVIEW_READY,
  SET_SCROLL_XY,
  SET_SCROLLING
} from "../../reducers/ui-reducer";
import SelectionAnimationOverlay, {
  SELECTION_ANIMATION_DURATION
} from "../SelectionAnimationOverlay/selection-animation-overlay";
import { AnimationStates } from "../../reducers/record-reducer";
import Onboarding from "../Onboarding/onboarding";
import {isDynamicIslandDevice} from "../../util/utils";

function HomeHeader(props) {
  if (props.loggedIn) return null;
  const opacity =
    props.selectedRecord ||
    props.isCurrentlyPlaying ||
    props.isCurrentlyPreviewing
      ? 0
      : 1;

  return (
    <div className={styles.header} style={{ opacity }}>
      <div className={styles.logoContainer}>
        <div className={styles.logoWrap}>
          <div className={styles.point}>Apple Music alternative client</div>
          <div className={styles.logo}>
            <img src={"/shelf_logo_no_border.png"} />
          </div>
        </div>
      </div>
    </div>
  );
}

function HomeFooter(props) {
  const opacity =
    props.selectedRecord ||
    props.isCurrentlyPlaying ||
    props.isCurrentlyPreviewing
      ? 0
      : 1;

  return (
    <div className={styles.footer} style={{ opacity }}>
      <div className={styles.buttonsBackground} />

      <div className={styles.buttonsContainer}>
        <div className={styles.points}>
          <div className={styles.point}>{/*Apple Music, The Good Parts*/}</div>
        </div>

        <div className={styles.buttons}>
          <button
            id="apple-music-unauthorize"
            onClick={() => {
              props.unauthenticate(props.cookies);
              return true;
            }}
          />
          <button
            id="apple-music-authorize"
            onClick={() => {
              props.authenticate(props.cookies);
              return true;
            }}
          />
        </div>
      </div>
    </div>
  );
}

function Home(props) {
  const {
    playbackState,
    selectedRecord,
    error,
    searchFieldFocused,
    loadingStep,
    loadingCount,
    loggedIn,
    isContainedInCatalyst
  } = props;

  const [showSelectedRecord, setShowSelectedRecord] = useState(
    props.selectedRecord
  );

  useEffect(() => {
    if (selectedRecord) {
      // going out
      setShowSelectedRecord(
        props.selectionAnimationState === AnimationStates.Out ||
          props.selectionAnimationState === AnimationStates.OutComplete
      );
    } else {
      //going in
      setShowSelectedRecord(
        props.selectionAnimationState === AnimationStates.Out ||
          props.selectionAnimationState === AnimationStates.OutComplete ||
          props.selectionAnimationState === AnimationStates.GoingInPrep
      );
    }

    setTimeout(
      () =>
        setShowSelectedRecord(
          props.selectedRecord &&
            (props.selectionAnimationState === AnimationStates.Out ||
              props.selectionAnimationState === AnimationStates.OutComplete ||
              props.selectionAnimationState === AnimationStates.GoingInPrep)
        ),
      props.selectedRecord ? SELECTION_ANIMATION_DURATION : 0
    );
  }, [props.selectionAnimationState]);

  const { clientHeight, clientWidth } = props;

  const containerStyle = {
    width: document.body.clientWidth,
    height: document.body.clientHeight,
    paddingTop: isContainedInCatalyst && 6
  };

  let loadingTop =
    isContainedInIosNativeApp() && props.clientHeight >= 812 ? 43 : 10;
  if (isContainedInCatalyst) loadingTop += 6;
  const loadingStepStyle = {
    top: loadingTop
  };

  function onScroll(evt) {
    if (!selectedRecord) {
      props.setScrollXY(
        evt.nativeEvent.contentOffset.x,
        evt.nativeEvent.contentOffset.y
      );
    }
    props.onScroll();
  }

  const loadingCountDesc = loadingCount && ` (${loadingCount})`;
  // isMobileWeb() && !props.mobileOnboardingDismissed ? (
  //     <Onboarding />
  // ) :

  // notch/dynamic island stuff
  let paddingTop=0;
  if (isContainedInIosNativeApp()) {
    paddingTop = 52;
    if (isDynamicIslandDevice()) {
      paddingTop=96;
    }
  }


  return (
    <SafeArea>
      {loadingStep &&
        !props.selectedRecord &&
        !props.playingRecord &&
        !props.playingTrack && (
          <div className={styles.loadingStep} style={loadingStepStyle}>
            loading {loadingStep}
            {loadingCountDesc} ...
          </div>
        )}

      {/*{constant(Constants.offsetInterfaceBy) > 0 && (*/}
      {/*  <div*/}
      {/*    style={{*/}
      {/*      height: constant(Constants.offsetInterfaceBy)*/}
      {/*    }}>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*      padding: 10,*/}
      {/*      fontSize: 22,*/}
      {/*      display: "flex",*/}
      {/*      flexDirection: "row",*/}
      {/*      width: "100%",*/}
      {/*      justifyContent: "space-around"*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {" "}*/}
      {/*    {props.selectionAnimationState}*/}
      {/*  </div>*/}
      {/*)}*/}

      <Flash />
      <Player />
      {!loggedIn && <HomeFooter {...props} />}
      <div className={styles.container} style={containerStyle}>
        <div
          style={
            {
              //opacity: showSelectedRecord ? 1 : 1
            }
          }
        >
          {showSelectedRecord && <SelectedRecord />}
        </div>
        <div
          style={{
            width: "100%",
            //transition: `all ${SELECTION_ANIMATION_DURATION}ms`,
            opacity: showSelectedRecord ? 0 : 1,
            display: "flex" //props.selectedRecord ? "none" : "flex",
          }}
        >
          <ScrollView
            scrollEventThrottle={100}
            containerStyle={{
              paddingTop: constant(Constants.offsetInterfaceBy),
              display: "flex" }}
            //  onScroll={onScroll}
            bounces={false}
            alwaysBouncesVertical={false}
            overScrollMode="never"
          >
            <HomeHeader {...props} />
            <div
              style={{
                paddingTop
              }}
            />

            {devMode() && error && (
              <div className={styles.error}>error: {error.message}</div>
            )}
            <DebugInfo />
            <Search />
            {isContainedInIosNativeApp() && <div style={{ height: 6 }} />}
            <div className={styles.pickedItem}>
              <PickedItem
                showPicker={!isMobile() || props.loggedIn}
                limit={constant(Constants.dataLoadingProfile).shelfLimit}
              />
            </div>
            <div style={{ padding: 12 }}>{isMe() && "..."}</div>

            {loggedIn && !isContainedInIosNativeApp() ? (
              <button
                className={styles.logoutButton}
                onClick={() => props.unauthenticate(props.cookies)}
              >
                log out
              </button>
            ) : (
              <div />
            )}

            <div style={{ height: 160 }} />
          </ScrollView>
        </div>
      </div>
      <SelectionAnimationOverlay />
    </SafeArea>
  );
}

const mapStateToProps = state => {
  const { uiPhase } = state.search;
  const showTitle =
    uiPhase === SEARCH_UI_PHASE_RECOMMENDED ||
    uiPhase === SEARCH_UI_PHASE_RESULTS ||
    uiPhase === SEARCH_UI_PHASE_ARTIST_SELECTED;

  return {
    isContainedInCatalyst: _.get(state.ui, "containerInfo.type") === "catalyst",
    selectionAnimationState: state.lightweightRecord.selectionAnimationState,
    isScrolling: state.ui.isScrolling,
    scrollYTarget: state.ui.scrollYTarget,
    searching:
      uiPhase === SEARCH_UI_PHASE_RESULTS ||
      uiPhase === SEARCH_UI_PHASE_ARTIST_SELECTED,
    error: state.serviceStatus.error,
    showTitle,
    selectedRecord: _.get(state, "lightweightRecord.selected.record"),
    loggedIn: state.authentication.loggedIn,
    clientHeight: _.get(state, "ui.documentElement.clientHeight"),
    clientWidth: _.get(state, "ui.documentElement.clientWidth"),
    searchFieldFocused: state.search.searchFieldFocused,
    loadingStep: state.lightweightRecord.loadingStep,
    loadingCount: state.lightweightRecord.loadingCount,
    pickedItem: state.ui.pickedItem,
    palette: state.ui.palette,
    previewStatus: state.playback.previewStatus,
    mouseIsOverPreviewingRecord: state.playback.mouseIsOverPreviewingRecord,
    mouseX: state.playback.mouseX,
    mouseY: state.playback.mouseY,
    isCurrentlyPreviewing: !!state.playback.currentlyPreviewingRecord,
    isCurrentlyPlaying: !!state.playback.currentlyPlayingRecord
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authenticate: cookies => {
      //dispatch(setPickedItem(recentsShelfItem));
      dispatch(authenticate(cookies, null, loadUserData));
    },
    unauthenticate: cookies => {
      dispatch(setPickedItem(staffPicksShelfItem));

      dispatch(unauthenticate(cookies));
    },
    onScroll: e => {
      dispatch(stopPreviewingRecord(null, true));
      dispatch({ type: SET_PREVIEW_READY, b: false });
    },
    setScrolling: b => {
      dispatch({ type: SET_SCROLLING, b });
    },
    refresh: () => {
      dispatch(hardRefreshShelf(0));
    },
    setScrollXY: (x, y) => {
      dispatch({ type: SET_SCROLL_XY, x, y });
    },
    updateDocumentElement: documentElement =>
      dispatch(updateDocumentElement(documentElement))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(Home));
