export function isDynamicIslandDevice() {
  // const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // const isIOS = /iPhone/i.test(userAgent); // Check if it's an iPhone
  //
  // if (!isIOS) return false;

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  // Logical resolutions for Dynamic Island models
  const dynamicIslandModels = [
    { width: 430, height: 932 }, // iPhone 14 Pro, iPhone 14 Pro Max
  ];

  // Check if the device matches known dimensions
  const isDynamicIsland = dynamicIslandModels.some(
    (model) =>
      (viewportWidth === model.width && viewportHeight === model.height) ||
      (viewportHeight === model.width && viewportWidth === model.height)
  );

  // Additional check for safe area insets (large top inset)
  const hasDynamicIslandInset =
    parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--safe-area-inset-top")) > 30;

  return isDynamicIsland || hasDynamicIslandInset;
}

// Usage
if (isDynamicIslandDevice()) {
  console.log("This device likely has a Dynamic Island!");
} else {
  console.log("No Dynamic Island detected.");
}

// NB: chatgpt generated
