import { combineReducers } from "redux";
import {
  LightweightRecordReducer as lightweightRecord,
  RecordReducer as record
} from "./record-reducer";
import authentication from "./authentication-reducer";
import search from "./search-reducer";
import playback from "./playback-reducer";
import ui from "./ui-reducer";
import debug from "./debug-reducer";
import serviceStatus from "./service-status-reducer";
import catalogs from "./catalogs-reducer";
import api from "./api-reducer";
import storage from "redux-persist/es/storage";
import { createMigrate, persistReducer } from "redux-persist";
import { constant, Constants } from "../helpers/flags";
import { jlog } from "../helpers";

const version = constant(Constants.dataLoadingProfile).reduxPersistVersion;
export const USE_REDUX_PERSIST = constant(Constants.dataLoadingProfile)
  .useReduxPersist;
jlog({ USE_REDUX_PERSIST, version });
const migrations = {
  [version]: state => {
    // migration clear out device state
    return {
      ...{}
    };
  }
};

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: [
    "ui",
    "playback",
    "debug",
    "serviceStatus",
    "lightweightRecord",
    "authentication"
  ],

  //blacklist: USE_REDUX_PERSIST ? null : ["record","playback","ui","search","authentication"],
  version,
  migrate: createMigrate(migrations, { debug: false })
};

const uiPersistConfig = {
  key: "ui",
  storage,
  whitelist: ["pickedItem", "meAlbumsCount"],
  version,
  migrate: createMigrate(migrations, { debug: false })
};

const recordPersistConfig = {
  key: "record",
  storage,
  blacklist: [
    "slugToRecordMap",
    "mbidToMusicbrainzOnlyMap",
    "recordSlugToDiscographyMap",
    "recordIdToLabelRecordsMap",
    "recordIdToTracksMap",
    "idToRecordMap",
    "dynamicShelves"
  ],
  version,
  migrate: createMigrate(migrations, { debug: false })
};

const lightweightRecordPersistConfig = {
  key: "lightweightRecord",
  storage,
  whitelist: ["selectionAnimationState"],
  version,
  migrate: createMigrate(migrations, { debug: false })
};

const catalogsPersistConfig = {
  key: "catalogs",
  storage,
  version: version + 1,
  migrate: createMigrate(migrations, { debug: false })
};

const playbackPersistConfig = {
  key: "playback",
  storage,
  whitelist: [
    "currentlyPlayingRecord",
    "currentlyPlayingTrack",
    "mediaItem",
    "queue"
  ],
  version,
  migrate: createMigrate(migrations, { debug: false })
};

const searchPersistConfig = {
  key: "search",
  storage,
  blacklist: ["hints", "results", "selectedArtist"],
  version,
  migrate: createMigrate(migrations, { debug: false })
};

const persistedArgs = {
  api,
  authentication,
  ui, //: persistReducer(uiPersistConfig, ui),
  debug,
  serviceStatus,
  playback, //: persistReducer(playbackPersistConfig, playback),
  record: persistReducer(recordPersistConfig, record),
  lightweightRecord: persistReducer(
    lightweightRecordPersistConfig,
    lightweightRecord
  ),
  search, //: persistReducer(searchPersistConfig, search),
  catalogs: persistReducer(catalogsPersistConfig, catalogs)
};

const unpersistedArgs = {
  serviceStatus,
  record,
  authentication,
  search,
  playback,
  lightweightRecord,
  ui,
  debug,
  api
};

const reducer = USE_REDUX_PERSIST
  ? persistReducer(rootPersistConfig, combineReducers(persistedArgs))
  : combineReducers(unpersistedArgs);

export default reducer;
