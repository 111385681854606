import { hot } from "react-hot-loader/root";
import React from "react";
import "./App.css";
import Home from "./components/Home/home";
import { connect } from "react-redux";
import {
  authenticate,
  autologin,
  unauthenticate
} from "./actions/authentication-actions";
import { withCookies, Cookies } from "react-cookie";
import { clearSelectedRecord, loadUserData } from "./actions/record-actions";
import { instanceOf } from "prop-types";
import { isContainedInIosNativeApp } from "./helpers/native-ios-mk-implementation";
import {
  Provider as SpectrumProvider,
  defaultTheme,
  Button
} from "@adobe/react-spectrum";

import {
  SET_HOVER_ELEMENT_ID,
  SET_PREVIEW_READY,
  SET_SCROLL_XY,
  SET_SCROLLING
} from "./reducers/ui-reducer";
import {
  CLEAR_DEBUG_LOG,
  TOGGLE_DEBUG_LOG,
  TURN_OFF_DEBUG_LOG
} from "./reducers/debug-reducer";
import {
  playPause,
  prev,
  next,
  stopPreviewingRecord,
  setIsMouseOverPreviewingRecord
} from "./actions/playback-actions";
import { updateDocumentElement } from "./actions/ui-actions";
import { initializeAnalytics } from "./analytics";
import RecordPage from "./pages/RecordPage/record-page";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { TURN_BIG_RECORD_TO_SIDE } from "./reducers/record-reducer";
import { SELECTION_ANIMATION_DURATION } from "./components/SelectionAnimationOverlay/selection-animation-overlay";
import FlexPlayground from "./components/Playground/flex-playground";
import Measure from "./components/Measure/measure";
import { MEASURE } from "./reducers/service-status-reducer";
import SwitchPlayground from "./components/Playground/switch-playground";
import SliderPlayground from "./components/Playground/slider-playground";
import DebugPlayground from "./components/Playground/debug-playground";
import PushButtonSwitchPlayground from "./components/Playground/pushbutton-switch-playground";
import OrbitPlayground from "./components/Playground/orbit-playground";
import { DataProvider } from "react-orbitjs";
import orbitStore from "./orbit/orbit-coordinator";
import {isMobile, isMobileWeb, jlog} from "./helpers";
import HomeLoggedOutRedShelf from "./components/Home/home-logged-out-red-shelf";
import { store } from "./index";
import {developerToken} from "./developer-token";

function initializeContainerSettings() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let isInCatalyst = params.get("catalyst");
  if (isInCatalyst) {
    store.dispatch({ type: "SET_CONTAINER_INFO", info: { type: "catalyst" } });
  }
}

if (typeof setImmediate === 'undefined') {
  global.setImmediate = (fn, ...args) => setTimeout(fn, 0, ...args);
}

class AppComponent extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  onMouseLeave = evt => {
    const { currentlyPlayingRecord } = this.props;
    if (!currentlyPlayingRecord) {
      //stopPreviewingRecord(currentlyPreviewingRecord);
    }
  };

  onMouseMove = event => {
    const { pageX: x, pageY: y, target } = event;
    const {
      currentlyPreviewingRecord,
      stopPreviewingRecord,
      setPreviewReady,
      setHoverElementId
    } = this.props;
    setHoverElementId(target.dataset.recordid);

    if (
      currentlyPreviewingRecord &&
      target.dataset.recordid !== currentlyPreviewingRecord.id
    ) {
      stopPreviewingRecord(currentlyPreviewingRecord);
    }

    setPreviewReady(true);
  };

  componentDidMount() {
    const {
      cookies,
      currentlyPreviewingRecord,
      stopPreviewingRecord
    } = this.props;

    initializeAnalytics();

    initializeContainerSettings();

    this.props.autologin(cookies);
    // setTimeout(() => {
    //   window.addEventListener("scroll", this.onScroll, true);
    // }, 1000);

    window.onhashchange = () => {
      this.props.dismiss();
      return true;
    };

    window.onfocus = function() {
      stopPreviewingRecord(currentlyPreviewingRecord);
    };

    window.onblur = function() {
      stopPreviewingRecord(currentlyPreviewingRecord, true);
    };

    // setInterval(() => {
    //   const hasFocus = document.hasFocus();
    //   if (!hasFocus) {
    //     stopPreviewingRecord(null, true);
    //   }
    // }, 1000);

    document.addEventListener("keydown", this.onKeydown);
    //document.addEventListener("mouseout", this.onMouseLeave);
    //document.addEventListener("mousemove", this.onMouseMove);
    //document.onmousemove = this.onMouseMove;

    // setInterval(() => {
    //   performance.measureMemory &&
    //     performance.measureMemory().then(result => {
    //       this.props.measureMemory(result.bytes);
    //     });
    // }, 5000);
  }

  onKeydown = event => {
    if (this.props.searchFieldFocused) return;
    if (event.ctrlKey || event.altKey || event.shiftKey) return;
    switch (event.keyCode) {
      case 27: // esc
        this.props.clearSelectedRecord();
        break;
      case 32: // space
        if (event.target === document.body && this.props.playingRecord) {
          this.props.playPause();

          event.preventDefault();
        }
        return false;
      case 37: // left
        this.props.prev();
        break;
      case 39: // right
        this.props.next();
        break;
      default:
        break;
    }
  };

  componentWillUnmount() {
    // window.removeEventListener("scroll", this.onScroll, true);
    document.removeEventListener("keydown", this.onKeydown);
  }

  onScroll = evt => {
    const { setScrolling } = this.props;

    // debounce(
    //   "scroll",
    //   () => {
    //     this.props.setScrollXY(evt.target.scrollLeft, evt.target.scrollTop);
    //   },
    //   1000
    // )
    // if (flag(Flags.previewingActive)) {
    //   debounce(
    //     "scroll",
    //     () => {
    //       this.props.setPreviewReady(false);
    //       // setScrolling(true);
    //       // this.props.setScrollXY(evt.target.scrollLeft, evt.target.scrollTop);
    //     },
    //     1000
    //   );
    // }
  };

  render() {
    const { loggedIn } = this.props;
    return (
      <Router>
        <Switch>
          <Route path={"/record/:id"}>
            <DataProvider dataStore={orbitStore}>
              <RecordPage />
            </DataProvider>
          </Route>
          <Route path={"/"}>
            {/*<DataProvider dataStore={orbitStore}>*/}
            {/*  <OrbitPlayground />*/}
            {/*</DataProvider>*/}
            <DataProvider dataStore={orbitStore}>
              <SpectrumProvider theme={defaultTheme}>
                {loggedIn ? (
                  <>
                    <Home {...this.props} />
                  </>
                ) : (
                  <HomeLoggedOutRedShelf />
                )}
              </SpectrumProvider>
            </DataProvider>
          </Route>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    containerInfo: state.ui.containerInfo || {},
    searchFieldFocused: state.search.searchFieldFocused,
    loggedIn: state.authentication.loggedIn,
    recentlyAdded: state.record.recentlyAdded,
    currentlyPreviewingRecord: state.playback.currentlyPreviewingRecord,
    currentlyPlayingRecord: state.playback.currentlyPlayingRecord,
    scrollPosition: state.ui.scroll
    // playingRecord: state.playback.currentlyPlayingRecord,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setHoverElementId: id => {
      dispatch({ type: SET_HOVER_ELEMENT_ID, id });
    },
    stopPreviewingRecord: (record, force) =>
      dispatch(stopPreviewingRecord(record, force)),
    setIsMouseOverPreviewingRecord: (bool, x, y) =>
      dispatch(setIsMouseOverPreviewingRecord(bool, x, y)),
    dismiss: () => {
      dispatch(clearSelectedRecord());
      setTimeout(
        () => dispatch({ type: TURN_BIG_RECORD_TO_SIDE, side: 0 }),
        SELECTION_ANIMATION_DURATION
      );
    },
    measureMemory: value =>
      dispatch({
        type: MEASURE,
        totalMemoryUsageBytes: value
      }),
    clearSelectedRecord: () => {
      dispatch(clearSelectedRecord());
      setTimeout(
        () => dispatch({ type: TURN_BIG_RECORD_TO_SIDE, side: 0 }),
        SELECTION_ANIMATION_DURATION
      );
    },

    setScrollXY: (scrollLeft, scrollTop) => {
      dispatch({ type: SET_SCROLL_XY, scrollTop, scrollLeft });
    },
    toggleDebugLog: () => {
      dispatch({ type: TOGGLE_DEBUG_LOG });
    },
    clearDebugLog: () => {
      dispatch({ type: CLEAR_DEBUG_LOG });
    },
    setScrolling: b => {
      dispatch({ type: SET_SCROLLING, b });
    },
    playPause: b => {
      dispatch(playPause());
    },
    next: b => {
      dispatch(next());
    },
    prev: b => {
      dispatch(prev());
    },
    setPreviewReady: b => {
      // dispatch(stopPreviewingRecord(null, true));
      dispatch({ type: SET_PREVIEW_READY, b });
    },
    autologin: cookies => {
      dispatch(autologin(cookies));
    },
    authenticate: cookies => {
      dispatch(authenticate(cookies, null, loadUserData));
    },

    unauthenticate: cookies => {
      dispatch(unauthenticate(cookies));
    },
    updateDocumentElement: documentElement =>
      dispatch(updateDocumentElement(documentElement))
  };
};
const App = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppComponent);

export default hot(withCookies(App));
