import React from "react";
import connect from "react-redux/es/connect/connect";
import {
  clearSearchHints,
  clearSearchResults,
  getSearchHints,
  setSearchText,
  setSearchUIPhase
} from "../../actions/search-actions";
import styles from "./search.module.scss";
import {
  SEARCH_UI_PHASE_RECOMMENDED,
  SEARCH_FIELD_FOCUSED
} from "../../reducers/search-reducer";
import Pane from "../Pane/pane";
import { debounce, jlog } from "../../helpers";
import DebugInfo from "../DebugInfo/debug-info";
import _ from "lodash";
import { setNormalPalette } from "../../actions/ui-actions";
import { reportEvent } from "../../analytics";
import { stopPreviewingRecord } from "../../actions/playback-actions";
import { clearSelectedRecord } from "../../actions/record-actions";
import { TouchableOpacity } from "react-native-web";
import PushButtonSwitch from "../Controls/PushButtonSwitch/push-button-switch";
import { SET_SHELF_LAYOUT } from "../../reducers/record-reducer";
import { staffPicksShelfItem } from "../ShelfPicker/shelf-picker";
class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = { text: "" };
    this.onChange({target:{value:""}})
  }
  onChange = event => {
    const text = event.target.value;
    this.setState({ text });
    this.props.onChange(text);
  };
  clearText = () => {
    this.setState({ text: "" });
    this.props.onChange("");
  };

  onClick = hint => {
    this.setState({ text: hint });
    this.props.onClick(hint);
  };

  componentDidUpdate(prevProps) {
    const { text } = this.props;
    if (text !== prevProps.text) {
      jlog({ settingState: text });
      this.setState({ text });
    }
  }
  // componentWillReceiveProps(prevProps) {
  //   const { text } = this.props;
  //   if (text && text !== this.state.text) {
  //     jlog({ settingState: text });
  //     this.setState({ text });
  //   }
  // }

  render() {
    const {
      onFocus,
      onBlur,
      palette,
      hints,
      playbackState,
      playingRecord,
      scrollViewRef,
      klass = "default"
    } = this.props;
    const { text = "" } = this.state;
    return (
      <div className={styles[klass]}>
        <div className={styles.containerContainer}>
          <div className={styles.container}>
            <div className={styles.inputContainer}>
              <input
                onChange={this.onChange}
                onFocus={() => onFocus(playingRecord)}
                value={text}
                onBlur={onBlur}
                placeholder={"Search Apple Music"}
              />
              <div className={styles.searchIcon}>
                <i className="fa fa-search" />
              </div>
              {text.length > 0 && (
                <div
                  onClick={() => this.clearText()}
                  className={styles.clearIcon}
                >
                  <i className="fa fa-times" />
                </div>
              )}
            </div>
            {hints && (
              <div className={styles.hints}>
                {_.slice(hints, 0, 5).map((hint, i) => {
                  return (
                    <button className={styles.hint}>
                      <TouchableOpacity
                        onPress={() => this.props.onHintPressed(hint)}
                      >
                        {hint}
                      </TouchableOpacity>
                    </button>
                  );
                })}
              </div>
            )}
          </div>
          {/*<div className={styles.controls}>*/}
          {/*  <PushButtonSwitch*/}
          {/*    states={[*/}
          {/*      {*/}
          {/*        code: "s",*/}
          {/*        icon: () => (*/}
          {/*          <div*/}
          {/*            className={styles.icon}*/}
          {/*            style={{*/}
          {/*              // border: "1px solid red",*/}
          {/*              display: "inline-block",*/}
          {/*              marginTop: 3*/}
          {/*            }}*/}
          {/*          >*/}
          {/*            <i className="fa fa-th-large" />*/}
          {/*          </div>*/}
          {/*        )*/}
          {/*      },*/}
          {/*      {*/}
          {/*        code: "m",*/}
          {/*        icon: () => (*/}
          {/*          <div*/}
          {/*            className={styles.icon}*/}
          {/*            style={{*/}
          {/*              marginTop: 1*/}
          {/*            }}*/}
          {/*          >*/}
          {/*            <i className="fa fa-square" />*/}
          {/*          </div>*/}
          {/*        )*/}
          {/*      }*/}
          {/*    ]}*/}
          {/*    code={this.props.layoutCode || this.props.pickedItem.layoutCode}*/}
          {/*    onChange={state =>*/}
          {/*      setImmediate(() =>*/}
          {/*        this.props.setShelfLayout(this.props.pickedItem.id, state.code)*/}
          {/*      )*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}
          {/* <Pane /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const layoutCode =
    state.record.shelves[
      _.get(state.ui, "pickedItem.id", staffPicksShelfItem.id)
    ] &&
    state.record.shelves[
      _.get(state.ui, "pickedItem.id", staffPicksShelfItem.id)
    ].layoutCode;

  return {
    pickedItem: state.ui.pickedItem,
    layoutCode,
    text: state.search.text,
    hints: state.search.hints,
    uiPhase: state.search.uiPhase,
    palette: state.ui.palette,
    playbackState: state.playback.playbackStateName,
    playingRecord: state.playback.currentlyPlayingRecord
  };
};

const mapDispatchToProps = (dispatch, getState) => {
  return {
    setShelfLayout: (shelfId, layout) => {
      dispatch({ type: SET_SHELF_LAYOUT, shelfId, layout });
    },
    onChange: text => {
      const empty = _.isEmpty(text);
      // debounce("searchHint", () => {
      //   if (empty) {
      //     // dispatch(clearSearchHints());
      //     // dispatch(setSearchUIPhase(SEARCH_UI_PHASE_RECOMMENDED));
      //     // dispatch(clearSearchResults());
      //   } else {
      //     dispatch(getSearchHints(text))
      //   }
      // }, 1000);
      dispatch(clearSearchResults());
      if (empty) {
        dispatch(clearSearchHints());
        dispatch(setSearchUIPhase(SEARCH_UI_PHASE_RECOMMENDED));
      } else {
        //debounce("event", () => reportEvent(`query`, s), 10000);
        debounce("searchHint", () => dispatch(getSearchHints(text)), 250);
      }
    },
    onFocus: playingRecord => {
      !playingRecord && dispatch(setNormalPalette());
      reportEvent("search box tapped");
      dispatch(stopPreviewingRecord());
      dispatch({ type: SEARCH_FIELD_FOCUSED, bool: true });
      //dispatch(setSearchUIPhase(SEARCH_UI_PHASE_RECOMMENDED));
    },
    onBlur: () => dispatch({ type: SEARCH_FIELD_FOCUSED, bool: false }),
    searchInstantly: term => dispatch(setSearchText(term)),
    onHintPressed: term => dispatch(setSearchText(term))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
