import React from "react";
import styles from "./home-logged-out-red-shelf.module.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { WHOMP_STATE_WITH_SAVED_STATE_JSON } from "../../reducers/record-reducer";
import {
  authenticate,
  unauthenticate
} from "../../actions/authentication-actions";
import { loadUserData } from "../../actions/record-actions";
import { setPickedItem } from "../../actions/ui-actions";
import { staffPicksShelfItem } from "../ShelfPicker/shelf-picker";
import { withCookies } from "react-cookie";
// import { tryPageSavedState1 } from "../../saved-states/try-page-saved-state-1";

const tryMessage =
  "Thanks for giving shelf a moment of your time! Now directing you to a sandbox account ...";
function HomeLoggedOutRedShelfComponent(props) {
  const { cookies, authenticate, unauthenticate, loggedIn } = props;
  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        <div className={styles.bullets}>
          <div className={styles.bullet}>Apple Music ... the good parts</div>
          <div className={styles.bullet2}>
            Your library, without noise.
          </div>

          <div className={styles.bullet2}>
            Free with Apple Music subscription.
          </div>
          {/*<div className={styles.bullet2}>&para; Find new + esoteric music</div>*/}
          {/*<div className={styles.bullet2}>Helpful, hype-free interface.</div>*/}
          {/*<div className={styles.bullet2}>*/}
          {/*  Syncs with, but will not mess with, your Apple Music.*/}
          {/*</div>*/}
          {/*<div className={styles.bullet2}>*/}
          {/*  &para; Apple Music account is required (free trial available)*/}
          {/*</div>*/}
          {/*          <div className={styles.appStoreLink}>*/}
          {/*<div style={{marginLeft:-6,zIndex:0}}>*/}
          {/*          <button*/}
          {/*              onClick={() =>*/}
          {/*                  (document.location.href =*/}
          {/*                      "https://apps.apple.com/us/app/shelf-fm/id1520498918")*/}
          {/*              }*/}
          {/*          >*/}
          {/*            <img src={'/download_app_store.svg'}/>*/}
          {/*          </button></div>*/}
          {/*          </div>*/}
          {/*<div className={styles.bullet2}>*/}
          {/*  &para; Eschewed: top-40 featured artists, algorithm-driven*/}
          {/*  playlists, "algotorial" content.*/}
          {/*</div>*/}
          {/*<div className={styles.bullet2}>*/}
          {/*  &para; Embraced: finding new + esoteric music through independent*/}
          {/*  labels.*/}
          {/*</div>*/}
          {/*<div className={styles.bullet2}>*/}
          {/*  &para; Thoughtful, innovative features like*/}
          {/*  preview-on-hover/longpress*/}
          {/*</div>*/}
          {/* <div className={styles.bullet2}>60 million songs. Different.</div>
          <div className={styles.bullet2}>
            ... independent labels, not curators or algorithms.
          </div>
          <div className={styles.bullet2}>
            ... record player, record store, record shelf.
          </div>
          <div className={styles.bullet2}>
            ... guide to the esoteric and the new.
          </div>           */}
        </div>
        <div className={styles.logo}>
          <img src={"/shelf_logo_no_border.png"}/>
        </div>
        <div className={styles.buttonsContainer}>
          <div className={styles.buttons}>
            {loggedIn ? (
              <button
                id="apple-music-unauthorize"
                onClick={() => {
                  unauthenticate(cookies);
                  return true;
                }}
              >
                Log Out Of Apple Music
              </button>
            ) : (
              <button
                id="apple-music-authorize"
                onClick={() => {
                  authenticate(cookies);
                  return true;
                }}
              >
                Log In To Apple Music
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    clientHeight: state.ui.documentElement.clientHeight,
    clientWidth: state.ui.documentElement.clientWidth,
    loggedIn: state.authentication.loggedIn
  };
};

const mapDispatchToProps = (dispatch, getState) => {
  return {
    authenticate: cookies => {
      //dispatch(setPickedItem(recentsShelfItem));
      dispatch(authenticate(cookies, null, loadUserData));
    },
    unauthenticate: cookies => {
      dispatch(setPickedItem(staffPicksShelfItem));

      dispatch(unauthenticate(cookies));
    }
  };
};

const HomeLoggedOutRedShelf = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(HomeLoggedOutRedShelfComponent));

export default HomeLoggedOutRedShelf;
